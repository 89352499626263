.details__preffered {
    display: flex;
    border-radius: 3px;
    border: 2px solid #eaf0f4;
    background-color: #f7f9fb;
    padding: 15px 40px 15px 25px;
    margin-bottom: 25px;
    color: #3b454b;

    @include media-breakpoint-down(xs) {
        padding: 15px;
    }

    .preffered {
        &__left {
            @include media-breakpoint-down(xs) {
                display: none;
            }
        }

        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 55px;
            border-radius: 50%;
            background-color: #8695e6;
            margin-right: 20px;

            @include media-breakpoint-down(md) {
                width: 50px;
                height: 50px;
            }

            svg {
                width: 27px;
                fill: #fff;
            }
        }

        &__title {
            line-height: 18px;
            margin-bottom: 5px;
            font-size: 19px;
            font-weight: 700;
        }

        &__cta {
            font-size: 18px;
            font-weight: 700;

            @include media-breakpoint-down(xs) {
                font-size: 17px;
            }

            a {
                color: #8695e6;
                border-bottom: 2px dashed rgba(134, 149, 230, 0.6);
                cursor: pointer;
                transition: $transition;

                &:hover {
                    color: lighten(#8695e6, 8%);
                }
            }
        }
    }
}