.main .login {
    &__wrapper {
        user-select: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 3rem 2rem;
        background: #fff;

        // Fullscreen
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include media-breakpoint-down(sm) {
            padding: 1rem 0;
        }
    }

    &__input:not(.ant-input) {
        background-color: #fff;
        color: $main-color;
        display: flex;
        height: 55px;
        border-radius: 10px;
        transition: all 0.25s ease;
        margin-bottom: 0.5rem;
        padding: 0 20px 0 10px;
        width: 270px; // fixed input width (coz: antd)

        &,
        input {
            // also cover case with nested input (coz: antd wrap input with Form.Item)
            margin-left: 0.5rem;
            font-size: 16px;
            font-weight: 500;
            &::placeholder {
                color: black;
            }
        }

        &,
        &:hover,
        &:focus {
            border: 2px solid $main-color !important;
            align-items: center;
        }

        &:focus {
            border: 2px solid !important;
        }

        &-suffix {
            position: absolute;
            right: 0;
            padding: 1.2rem 0.75rem;
            top: 0;
            bottom: 0;
            font-size: 1.2rem;
        }
    }

    &__form {
        text-align: center;
        background: #fff;
        padding: 2rem;
        border-radius: 10px;
        //box-shadow: 0 1px 2px #292f5c12;
        &-group {
            position: relative;
        }
    }

    &__btn[class] {
        margin-top: 20px;
        height: unset;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 0.5rem 3rem;
        font-size: 18px;
        //box-shadow: 0 8px 12px rgba($primary, 0.3);
    }

    &__heading {
        font-size: 2rem;
        font-weight: normal;
        margin-bottom: 1rem;
        text-align: left;
        animation: zoomIn 0.35s ease 0.2s both;
        will-change: transform;

        .anticon {
            // margin-right: 0.5ch;
        }
    }

    &__link {
        display: block;
        margin-top: .5rem;
        color: black;
        font-size: 1rem;
    }

    // Animations
    &__form,
    &__input,
    &__btn,
    &__link {
        opacity: 0;
        animation: fadeInUp 0.25s ease both;
    }
    &__input {
        animation-delay: 0.1s;
    }
    &__btn {
        animation-delay: 0.2s;
    }
    &__link {
        animation-delay: 0.4s;
    }

    // ANTD FORMS
    .ant-form {
        .ant-row {
            margin: 0;
        }
        .ant-input {
            border-radius: 0;
            &:focus {
                box-shadow: none !important; // fix
            }
        }
        .ant-form-item-explain {
            opacity: 0.8;
            position: relative; // perfect pixel
            top: -8px; // perfect pixel
        }
        .ant-form-item-children-icon {
            top: 12px; // perfect pixel
            bottom: 0;
            height: 80%; // perfect pixel
            display: flex;
            align-items: center;
        }
        .ant-input-prefix:empty {
            display: none; // dump fix
        }
    }
}
