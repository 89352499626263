.feature {
    &__row {
        display: grid;
        margin: 5px 0 20px;
        grid-gap: 14px;
        grid-template-columns: repeat(auto-fill, 270px);

        @include media-breakpoint-down(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__outer {
        width: 100%;
        max-width: 270px;

        @include media-breakpoint-down(lg) {
            margin-bottom: 15px;
            max-width: 50%;
            flex: 45%;

            &:nth-child(even) {
                margin-left: 15px;
            }
        }

        @include media-breakpoint-only(md) {
            max-width: 340px;
        }

        @include media-breakpoint-down(xs) {
            flex: 100%;
            max-width: 90%;

            &:nth-child(even) {
                margin-left: 0;
            }
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        //background-color: $grey-color;
        border: 1px solid $grey-color;

        &:not(.preferredwriter) {
            .feature__footer {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &.preferredwriter {
            .select__placeholder {
                border: none;
                font-size: 18px;
                height: 46px;
                color: #9dadb7;

                &:hover {
                    background-color: transparent;
                    box-shadow: none;
                    color: $main-color;

                    .select__arrow {
                        fill: $main-color;
                    }
                }
            }
        }

        &:hover {
            .feature__title {
                color: $main-color;
            }
        }

        &.on {
            &:hover {
                .feature__title {
                    color: #3b454b;
                }
            }
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 20px 10px;
        border-right: 2px solid #fff;
    }

    &__price-tag {
        position: absolute;
        left: 10px;
        top: 10px;
        font-size: 14px;
        color: $main-color;
        font-weight: 500;
        opacity: 0;
        transition: $transition;

        &.on {
            opacity: 1;
        }
    }

    &__check {
        position: absolute;
        right: 10px;
        top: 10px;
        fill: $grey-color;
        width: 10px;
        height: 16px;
        transition: $transition;

        &.on {
            fill: $main-color;
        }
    }

    &__icon {
        width: 61px;
        height: 46px;
        margin-bottom: 10px;
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        // border-bottom: 2px dashed #9bacb6;
        transition: $transition;
        cursor: default;
    }

    &__footer {
        min-width: 100px;
        min-height: 54px;
        padding: 10px;
        flex-grow: 1;
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 46px;
        border-radius: 10px;
        background-color: $main-color;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        transition: $transition;

        &:hover {
            //box-shadow: 0 10px 35px $main-color;
        }

        &.on {
            background-color: $grey-color;
            color: $color-text;
            font-size: 18px;

        }
    }

    &__another-writer {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 225px;

        .another-text {
            font-size: 14px;
            transition: $transition;
        }

        .another-label {
            padding-left: 0;
            transition: $transition;

            svg {
                display: inline-block;
                margin: 0 7px 0 0;
                transition: $transition;
                opacity: 0;
            }

            &::before {
                width: 15px;
                height: 15px;
                top: 4px;
                border: 1px solid #9bacb6;
                border-radius: 3px;
                background: #fff;
            }
        }

        .another-input:checked + .another-label {
            color: $main-color;

            &::before {
                transform: initial;
                opacity: 0;
                left: 0;
            }

            svg {
                fill: $main-color;
                opacity: 1;
            }
        }
    }
}
