.orderform {
    &__wrapper {
        display: flex;
        position: relative;
        z-index: 1;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
    }

    &__right {
        flex-grow: 1;
        @include media-breakpoint-down(md) {
            order: -1;
        }
    }

    &__label {
        position: relative;
        margin-bottom: 0;
        line-height: 22px;

        &-text {
            display: inline-block;
            position: relative;
            color: #9bacb6;
            font-size: 14px;
            line-height: 15px;
            margin-bottom: 13px;
            font-weight: 500;
            cursor: default;

            &::after {
                content: "";
                position: absolute;
                display: block;
                left: 0;
                bottom: -3px;
                width: 100%;
                // border-bottom: 2px dashed #9bacb6;
            }
        }

        &-message {
            color: #cc2f31;
            font-size: 20px;
            font-weight: 700;
        }
    }

    &__label-message {
        font-size: 14px;
    }

    &__tooltip {
        position: absolute;
        width: 270px;
        background-color: rgba(59, 69, 75, 0.85);
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        border-radius: 5px;
        bottom: 50px;
        left: -80px;
        text-align: center;
        padding: 7px 10px 5px;
        line-height: 16px;
        z-index: 50;
        pointer-events: none;
        opacity: 0;
        transition: $transition;

        @include media-breakpoint-down(sm) {
            left: 0;
            text-align: left;
        }

        &::after {
            content: "";
            position: absolute;
            display: block;
            border: 9px solid rgba(59, 69, 75, 0.85);
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            left: 50%;
            transform: translateX(-50%);
            bottom: -18px;

            @include media-breakpoint-down(sm) {
                left: 15%;
            }
        }
    }

    &__check-arrow {
        fill: $main-color;
        margin-right: 12px;
        transition: $transition;
    }

    &__left {
        display: flex;
        flex-direction: column;
        min-width: 270px;
        padding-right: 24px;

        @include media-breakpoint-down(md) {
            width: 100%;
            padding-right: 0;
        }
    }

    &__all-steps {
        text-align: right;

        a {
            font-size: 14px;
            font-weight: 500;
            color: #9bacb6;
        }
    }

    &__navigation {
        min-height: 33px;

        @include media-breakpoint-up(lg) {
            position: sticky;
            top: 49px;
        }

        @include media-breakpoint-down(md) {
            order: 1;
        }

        a {
            font-size: 14px;
            font-weight: 500;
            color: #9bacb6;
        }
    }

    &__details-exclamation {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #9bacb6;
        margin: -13px 0 16px;

        @include media-breakpoint-down(xs) {
            font-size: 13px;
        }

        svg {
            width: 16px;
            height: 16px;
            fill: #9bacb6;
            margin-right: 8px;
            margin-bottom: 5px;
        }

        .fa-exclamation-circle {
            margin-right: 5px;
        }
    }

    &__links {
        &-wrapper {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__link {
        &-default {
            border: none;
            background: none;
            margin-right: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #9bacb6;
        }
    }

    @import 'pills';
    @import 'select';
    @import 'counter';
    @import 'formfield';
    @import 'file';
    @import 'summary';
    @import 'spacing';
    @import 'checkbox';
    @import 'urgency';
    @import 'feature';

    // [Category Resume]
    &.resume {
        .counter {
            &__row {}

            &__group {
                @include media-breakpoint-only(lg) {
                    &:nth-child(2) {
                        flex: 1 1 auto;
                        align-items: flex-end;
                    }
                }
            }

            &__item {
                @include media-breakpoint-down(lg) {
                    // width: 255px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }

        .select {
            &__row {
                .select__group:first-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__fill-in {
        display: inline-block;
        text-align: center;
        margin-bottom: 12px;
        max-width: 255px;
        font-size: 14px;
        //font-weight: 700;
        line-height: 18px;
        color: $color-text;
        min-height: 55px;
        border-radius: 8px;
        background-color: #ffffff;
        padding: 0.5rem 2.7rem;

        span.asterisk {
            color: #d76c6d;
        }
    }

    &__fillbtn-wrapper {
        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    &__next-btn {
        $next-btn-color: $main-color;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 100%;
        // border: none;
        border: 2px solid $next-btn-color;
        outline: none;
        background-color: $next-btn-color;
        // background-image: linear-gradient(to bottom, #95f08b 0%, #1db01b 100%);
        // box-shadow: 0 5px 25px 0 $next-btn-color;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        min-height: 55px;
        margin-bottom: 12px;
        transition: $transition;
        white-space: nowrap;

        @include media-breakpoint-down(md) {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }

        &:hover {
            //box-shadow: 0 10px 25px rgba($next-btn-color, .5);
            color: #ffffff;
        }

        &.logged {
            background: transparent;
            border: 2px solid var(--grey-color, #f7f9fa);
            color: $next-btn-color;
            box-shadow: none;

            &:hover {
                background-color: $next-btn-color;
                // background-image: linear-gradient(to bottom, #95f08b 0%, #1db01b 100%);
                // border: none;
                border: 2px solid $next-btn-color;
                color: #fff;
            }
        }

        &.disabled {
            pointer-events: none;
        }
    }

    &__cancel-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        margin: 10px 0 12px;
        width: 100%;
        height: 55px;
        border: 2px solid $grey-color;
        background-color: transparent;
        color: #d76c6d;
        font-size: 18px;
        font-weight: 500;
        //box-shadow: 0 5px 15px rgba(181, 187, 190, 0.5);
        transition: $transition;

        @include media-breakpoint-down(md) {
            max-width: 300px;
            margin: 25px auto 12px;
        }

        &:hover {
            //box-shadow: 0 15px 25px rgba(181, 187, 190, 0.6);
        }
    }

    &__checkout-wrapper {
        .pills {
            &__box {}

            &__btn {
                max-width: 49%;

                @include media-breakpoint-down(sm) {
                    max-width: unset;
                }
            }
        }
    }

    &__express-btn {
        $express-btn-color: #fb6738;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin: 20px 0 12px;
        width: 100%;
        height: 46px;
        border: 2px solid $grey-color;
        background-color: transparent;
        color: $express-btn-color;
        font-size: 18px;
        font-weight: 500;
        transition: $transition;

        @include media-breakpoint-down(md) {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }

        &:hover {
            //box-shadow: 0 10px 35px $express-btn-color;
            background-color: $express-btn-color;
            // background-image: linear-gradient(to top, #d33cff 0%, #7875ff 50%, #51faf0 100%);
            border: none;
            color: #fff;

            svg {
                fill: #fff;
            }
        }

        &.logged {
            box-shadow: none;
            background-color: $express-btn-color;
            // background-image: linear-gradient(to top, #d33cff 0%, #7875ff 50%, #51faf0 100%);
            border: none;
            color: #fff;

            svg {
                fill: #fff;
            }

            &:hover {
                //box-shadow: 0 10px 35px $express-btn-color;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $express-btn-color;
            margin-right: 7px;
            transition: $transition;
        }
    }

    &__badges {
        display: flex;
        justify-content: center;

        svg {
            fill: #cbd4d9;
            width: 43px;
            height: 29px;
            margin: 3px;
        }
    }
}

.orderform__label-text:hover+.orderform__tooltip {
    opacity: 1;
}

@import 'navbar';