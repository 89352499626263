.ordertable {
    border: 2px solid $grey-color;
    border-radius: 8px;
    &__item {
        display: grid;
        grid-template-columns: minmax(100px, 290px) minmax(200px, 1fr) minmax(100px, 160px) 15px minmax(100px, 165px);

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
        }
        border-bottom: 2px solid $grey-color;
        padding: 20px;
        &:last-child {
            border-bottom: none;
        }

        &:nth-child(2n + 3) {
            background-color: #f8f9fb;

            .ordertable__files-inner {
                background-color: #f8f9fb;
            }
        }

        .ordercard__deadline {
            margin-top: 0;
            color: #9bacb6;
            font-size: 14px;
        }

        .modify-btn {
            font-size: 16px;
        }

        &.unpaid {
            .ordercard {
                &__price {
                    font-size: 20px;
                    line-height: 36px;
                    color: $color-text;
                    font-weight: 600;
                    text-align: center;

                    .colored-price,
                    .colored-percent {
                        //color: #78cd62;
                    }
                }

                &__discount-info {
                    display: block;
                    width: initial;
                    font-size: 13px;
                    line-height: 14px;
                    color: #9bacb6;

                    .full-price {
                        text-decoration: line-through;
                    }
                }

                &__btn {
                    background-color: $main-color;

                    span {
                        line-height: 18px;
                    }

                    &:hover {
                        //box-shadow: 0 1px 5px 0 #59d053;
                    }
                }
            }
        }

        &.unpaid,
        &.hold {
            .ordercard__deadline {
                font-size: 15px;
                margin-top: 8px;
                color: #3b454b;
            }
        }

        &.completed {
            .ordercard__btn {
                background-color: #ebf0f4;
                color: #7f93f4;
                font-size: 16px;

                &:hover {
                    //box-shadow: 0 1px 5px 0 #7f92f3;
                }
            }
        }

        &.hold {
            .ordercard__btn {
                background-color: #ebf0f4;
                color: #d76c6d;
                font-size: 16px;

                &:hover {
                    //box-shadow: 0 1px 5px 0 #d76c6d;
                }
            }
        }
    }

    &__status {
        @include media-breakpoint-up(lg) {
            padding-right: 10px;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        @include media-breakpoint-down(md) {
            text-align: center;
        }


        .ordercard__deadline {
            padding-left: 17px;
            @include media-breakpoint-down(md) {
                justify-content: center;
            }

            .deadline-label {
                display: none;
            }
        }

        .ordercard__messages {
            height: initial;
            margin: 3px 0;
            justify-content: flex-start;
            padding-left: 17px;
            background: transparent;
        }

        .ordercard__feedback {
            background-color: transparent;
            margin: 0;
            height: initial;
            align-items: start;
            padding-left: 17px;

            .feedback-title {
                font-size: 13px;
            }
        }

        .ordercard__revision {
            height: initial;
            margin: 3px 0;
            justify-content: flex-start;
            padding-left: 17px;
            background: transparent;
        }
    }

    &__details {
        @include media-breakpoint-up(lg) {
            padding-right: 20px;
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }

        @include media-breakpoint-down(md) {
            padding: 20px 0;
        }

        .ordercard__title {
            margin-bottom: 5px;
        }

        .ordercard__details-box {
            line-height: 17px;
        }

        .ordercard__pages,
        .ordercard__files {
            white-space: nowrap;
        }
    }

    &__price {
        @include media-breakpoint-up(lg) {
            grid-column: 3 / 4;
            grid-row: 1 / 2;
        }
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            justify-content: center;
            padding-bottom: 10px;
        }

        .ordercard__price {
            font-size: 20px;
            text-align: left;
        }
    }

    &__action {
        position: relative;
        display: flex;
        align-items: center;
        @include media-breakpoint-up(lg) {
            grid-column: 5 / 6;
            grid-row: 1 / 2;
        }

        .ordercard__btn {
            margin-top: 0;
        }

        .progress-text {
            font-size: 14px;
        }

        .ordercard__progress {
            width: 100%;
        }
    }

    &__more {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        grid-column: 6 / 7;
        grid-row: 1 / 2;

        &-menu {
            position: absolute;
            display: flex;
            flex-direction: column;
            right: 0;
            top: calc(50% - 23px);
            width: 160px;
            height: 118px;
            background-color: #f7f9fa;
            box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
            border-radius: 8px;
            padding: 4px 38px 4px 25px;
            opacity: 0;
            transform: scale(0);
            transform-origin: 100% 0%;
            pointer-events: none;
            z-index: 10;

            &.opened {
                box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
                transform: scale(1);
                opacity: 1;
                pointer-events: all;
                transition: $transition;
            }

            .ordercard__action {
                justify-content: flex-end;
                padding: 0;
                height: 36px;

                &:not(:first-child) {
                    border-top: 2px solid #eaf0f4;
                }
            }

            .similar {
                border: none;
                max-width: initial;
            }
        }

        &-btn {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            padding: 0;
            border: none;
            box-shadow: none;
            background-color: transparent;

            &:hover,
            &.opened {
                z-index: 10;

                div {
                    background-color: $main-color;

                    &::before,
                    &::after {
                        background-color: $main-color;
                    }
                }
            }

            div {
                position: relative;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #9bacb6;
                transition: $transition;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #9bacb6;
                    transition: $transition;
                    left: 0;
                }

                &::before {
                    top: -8px;
                }

                &::after {
                    bottom: -8px;
                }
            }
        }
    }

    &__files-wrapper {
        grid-column: 2 / 4;
        grid-row: 1 / 2;
        overflow: hidden;
    }

    &__files-inner {
        display: flex;
        align-items: center;
        height: 100%;
        background-color: #fff;
        overflow-y: hidden;
        overflow-x: auto;
        min-height: 71px;
        height: 100%;
        transform: translateX(100%);
        transition: 0.35s;
        @include customize-scrollbars(4px, $main-color, #fbfbfb, visible);

        &.slided {
            transform: translateX(0);
        }

        .ordercard__files {
            display: flex;
            align-items: center;
        }

        .ordercard__file {
            min-width: 250px;
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;

            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        padding: 5px 0 5px 17px;

        .eye-icon {
            width: 15px;
            fill: currentColor;
            margin-right: 6px;
            margin-bottom: 2px;
        }
    }
}

.ordertable__item {

    &.unpaid,
    &.hold {
        .ordertable {
            &__header {
                display: inline-flex;
                align-items: center;
                background-color: $warning;
                color: #fff;
                min-height: 38px;
                min-width: 252px;
                border-radius: 8px;
            }
        }
    }

    &.paid,
    &.in-progress,
    &.modified,
    &.offered {
        .ordertable {
            &__header {
                color: #7ac8ed;
            }
        }
    }

    &.completed {
        .ordertable {
            &__header {
                color: #78cd62;
            }
        }
    }

    &.canceled {
        .ordertable {
            &__header {
                color: #9bacb6;
                text-decoration: line-through;
                text-decoration-color: #eaf0f4;
            }
        }
    }

    &.refunded {
        .ordertable {
            &__header {
                color: #9bacb6;
            }
        }
    }

    &.is-hidden {
        .ordertable {
            &__header {
                color: #c5ccd3;
                cursor: default;
            }

            &__date-created {
                font-size: 14px;
                padding-left: 17px;
                color: #9bacb6;
            }

            &__details {
                .ordercard {
                    &__files {
                        font-size: 13px;
                        cursor: default;
                    }

                    &__title,
                    &__files,
                    &__topWriters,
                    &__qalityFeature,
                    &__preferredWriter {
                        color: #9bacb6;
                    }
                }
            }

            &__price {
                font-size: 20px;
                text-align: left;
                color: #9bacb6;
                font-weight: 700;
            }

            &__restore-button {
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                background-color: #ebf0f4;
                color: #3b454b;
                border-radius: 8px;
                width: 100%;
                min-height: 45px;
                transition: 0.25s;
                font-size: 18px;
                font-weight: 600;
                margin: 15px 0 0;

                &:hover {
                    color: $main-color;
                    //box-shadow: 0 1px 5px 0 $main-color;
                }
            }
        }
    }
}

.tablehead {
    padding: 5px 0 7px;
    border-bottom: 2px solid $grey-color;

    @include media-breakpoint-down(md) {
        display: none;
    }

    &__item {
        color: #9bacb6;
        font-size: 14px;
        font-weight: 500;

        &:first-child {
            padding-left: 20px;
        }
    }
}