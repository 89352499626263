:root {
    --width-button: 157px;
}

.spacing {
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        position: relative;
        width: 255px;
        height: 46px;
        border-radius: 8px;
        border: 2px solid transparent;
        background-color: $grey-color;
        color: #3b454b;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        transition: .25s;

        &.disabled {
            color: #ccd5db;
            box-shadow: none;
            pointer-events: none;

            .spacing {
                &__button {
                    background: #ccd5db;
                }
            }
        }

        &.focused {
            border-color: $main-color;
        }
    }

    &__name {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        font-weight: 500;

        &:hover {
            transition: .2s;
            color: $main-color;
        }

        &.first {}

        &.last {}

        // opacity: 1;
        // transition: .1s;
        // &.active {
        //     opacity: 0;
        // }
    }

    &__input {
        visibility: hidden;
    }

    &__button {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        width: var(--width-button, 157px);
        height: 44px;
        border-radius: 4px;
        font-weight: 500;
        background-color: $main-color;
        transition: .2s;

        &.first {
            left: 0;
        }

        &.last {
            left: 95px;
        }

        span {

            // transition: .2s linear;
            &:not(.active) {
                position: absolute;
                visibility: hidden;
                width: 100%;
                opacity: 0;
                transition: none;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: #ffffff;
            font-size: 18px;
            font-weight: 400;

            &.active {
                opacity: 1;
            }
        }
    }
}