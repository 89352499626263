.checkbox {
    &__group {
        margin-top: 12px;
    }

    &__input {
        visibility: visible;
        width: 0;
        height: 0;
        -webkit-appearance: none;
    }

    &__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 2px solid #e1e6e8;
        margin-top: 0;

        &-arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: $main-color;
            opacity: 0;
            transition: .2s;
        }
    }

    &__label {
        display: inline-flex;
        align-items: center;
        color: #3b454b;
        font-size: 16px;
        font-weight: 500;
        //height: 40px;
        padding-left: 40px;
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
            //font-size: 19px;
        }
        @include media-breakpoint-down(xs) {
            //font-size: 16px;
            //padding-left: 52px;
        }
        &::before {
            display: none;
        }


        &:hover {
            &::before {
                border: 2px solid $main-color;
            }
        }

        span.calls-box {
            // border-bottom: 2px dashed #9bacb6;
        }

        span.asterisk {
            color: #cc2f31;
        }
    }

    &__link {
        color: #85a7ff;
        @include media-breakpoint-down(xs) {
            &:first-child {
                // white-space: nowrap;
            }
        }
    }
}

.checkbox__input:focus+.checkbox__label .checkbox__button {
    border: 2px solid $main-color;
    //box-shadow: 0 10px 35px $main-color;
}

.checkbox__input:checked+.checkbox__label .checkbox__button {
    border: 2px solid $main-color;
    &-arrow {
        width: 10px;
        opacity: 1;
    }
}