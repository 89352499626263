.privacy {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
  width: 100vw;
  max-width: 888px;
  overflow: hidden;
  padding: 60px 90px 30px;

  @include media-breakpoint-down(lg) {
    padding: 40px 40px 30px;
    max-width: 688px;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 20px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 80px;
  }

  &__heading {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 25px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 21px;
      margin-bottom: 15px;
    }
  }

  &__subheading {
    @include media-breakpoint-down(xs) {
      line-height: 22px;
    }
  }

  //&__btn-wrapper {
  //  display: flex;
  //  justify-content: space-between;
  //  margin-bottom: 20px;
  //
  //  @include media-breakpoint-down(xs) {
  //    flex-wrap: wrap;
  //    justify-content: center;
  //  }
  //}

  &__btn {
    &-wrapper {
      //border: 2px solid $grey-color;
      border-radius: 8px;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
    }
    &-heading {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      //border-bottom: 2px solid $grey-color;
      padding: 10px;
    }
    &-list {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }
  }

  &__tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 0 0 calc(50% - 8px);
    border-radius: 8px;
    background-color: #fff;
    border: none;
    box-shadow: none;
    -webkit-appearance: none;
    padding: 15px;
    height: 46px;
    transition: $transition;
    color: $color-text;

    border: 2px solid $color-text;


    @include media-breakpoint-down(md) {
      padding: 10px;
      line-height: 22px;
      flex: 0 0 100%;

      &:first-child {
        margin-bottom: 20px;
      }
    }

    &:hover {
      border-color: $main-color;
      background-color: $main-color;
      color: #fff;
    }

    span {
      display: block;

      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }

      &:last-child {
        font-size: 18px;
        font-weight: 600;

        @include media-breakpoint-down(sm) {
          font-size: 17px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 16px;
        }
      }
    }
  }

  &__footer {
    display: flex;
    border-top: 2px solid $grey-color;
    margin-top: auto;
    padding-top: 20px;

    @include media-breakpoint-down(xs) {
      margin-top: 20px;
    }
  }

  &__back-btn,
  &__close-btn {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 0;

    @include media-breakpoint-down(xs) {
      line-height: 18px;
    }
  }

  &__back-btn {
    color: #9bacb6;

    @include media-breakpoint-down(xs) {
      text-align: left;
    }
  }

  &__close-btn {
    margin-left: auto;
    color: $error-color;
  }

  &__stats-wrapper {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }

  &__stats-left,
  &__stats-right {
    flex: 0 0 50%;
    padding-right: 20px;

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
    }
  }

  &__stats-right {
    //margin-bottom: 10px;
  }

  &__list {
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 5px;
    }

    &.clean {
      li {
        background-color: $grey-color;
        margin-bottom: 4px;
        border-radius: 8px;
        padding: 10px 20px;
        .label {
          display: inline-block;
          font-weight: 700;
          width: 130px;
        }
      }
    }

    &.nested {
      margin: 5px 0 50px;

      @include media-breakpoint-down(sm) {
        margin: 5px 0;
      }

      li {
        position: relative;
        padding-left: 30px;

        @include media-breakpoint-down(xs) {
          padding-left: 15px;
        }

        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 4px;
          height: 4px;
          background-color: #000;
          border-radius: 50%;
          left: 0;
          top: 10px;
        }

        span {
          font-weight: 400;

          &:first-child {
            display: inline-block;
            width: 70px;
          }
        }
      }
    }

    &.scroll {
      height: 400px;
      overflow-y: auto;
      @include customize-scrollbars(6px, $main-color, #fbfbfb, visible);
      padding-right: 20px;

      @include media-breakpoint-down(xs) {
        height: initial;
        overflow-y: visible;
        padding-right: 0;
      }
    }
  }

  &__list-item {
    label {
      font-weight: 700;
      font-size: 18px;
      height: initial !important;
      padding-left: 0;
      color: #3b454b;

      &:before {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        line-height: 18px !important;
      }
    }
  }

  &__checkbox-info {
    padding-left: 45px;

    @include media-breakpoint-down(xs) {
      padding-left: 40px;
      line-height: 22px;
    }
  }

  &__download-btn,
  &__delete-btn {
    width: 295px;
    height: 46px;
    border-radius: 8px;
    border: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;
    transition: 0.25s;
    @include media-breakpoint-down(md) {
      min-height: 46px;
      height: auto;
      width: 100%;
      padding: 8px 10px;
    }
  }

  &__download-btn {
    //border: 2px solid #f7f9fa;
    color: #ffffff;
    background-color: #7f92f3;
    font-size: 18px;
    margin-right: 10px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }

    &:hover {
      background-color: #aebbff;
      border: 2px solid #aebbff;
      color: #fff;
    }
  }

  &__delete-btn {
    background-color: $error-color;
    color: #fff;

    @include media-breakpoint-down(sm) {
      line-height: 21px;
    }
  }

  .settings-acc {
    margin-bottom: 20px;

    &__title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;

      @include media-breakpoint-down(xs) {
        font-size: 19px;
      }
    }

    &__list {
      padding-left: 20px;

      li {
        list-style-type: disc;
      }
    }

    .accordion__item {
      background-color: #f7f9fa;
      border-radius: 8px;
      overflow: hidden;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    .accordion__toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      min-height: 46px;
      padding: 10px 25px;

      &[aria-expanded='true'] {
        svg {
          transform: rotate(180deg);
          fill: $main-color;
        }
      }

      svg {
        width: 14px;
        height: 13px;
        transition: $transition;
      }
    }

    .accordion__collapse {
      &:not(.expanded) {
        height: 0;
      }
    }

    .accordion__collapse-container {
      padding: 0 25px 28px;

      @include media-breakpoint-down(xs) {
        line-height: 22px;
      }
    }
  }

  .checkbox {
    &__label {
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: 600;
      }

      &:hover {
        .checkbox__button {
          border-color: $main-color;
        }
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      order: -1;
      width: 20px;
      height: 20px;
      border: 2px solid #e1e6e8;
      background-color: #ffffff;
      border-radius: 4px;
      margin-right: 15px;
      transition: $transition;

      @include media-breakpoint-down(xs) {
        //min-width: 30px;
        //height: 30px;
        margin-right: 10px;
        flex-shrink: 0;
      }

      svg {
        width: 10px;
        fill: #fff;
        transition: $transition;
      }
    }

    &__link {
      color: #7f92f3;
      font-weight: 600;
      transition: $transition;
      &.active {
        color: $primary;
      }

      &:hover {
        color: lighten(#7f92f3, 5%);
      }
    }
  }

  .checkbox__input:checked + .checkbox__label {
    .checkbox__button {
      border-color: $main-color;

      svg {
        fill: $main-color;
      }
    }
  }
}

.wipe {
  &__modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 11000;
    opacity: 0;
    pointer-events: none;
    transition: 0.25s;

    &.is-open {
      pointer-events: auto;
      opacity: 1;
    }

    .heart {
      position: absolute;
      top: -110px;
      left: 50%;
      transform: translateX(-50%);
      width: 129px;
      height: 91px;
      fill: #fff;
    }
  }

  &__inner-wrapper {
    position: relative;
    width: 100%;
    max-width: 345px;
    min-height: 200px;
    background-color: #fff;
    border-radius: 8px;
    padding: 0 25px 15px;
  }

  &__top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d76c6d;
    height: 43px;
    color: #fff;
    padding: 0 0 0 25px;
    border-radius: 8px 8px 0 0;
    font-size: 17px;
    margin: 0 -25px 20px;
  }

  &__close {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 13px;
      height: 2px;
      left: 50%;
      top: 50%;
      background-color: #fff;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__heading {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  &__text {
    line-height: 20px;
  }

  &__footer {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  &__del-btn {
    cursor: pointer;
    color: $error-color;
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: #eaf0f4;
    margin: 10px 0;
  }

  &__cancel {
    cursor: pointer;
    color: $main-color;
    line-height: 22px;

    @include media-breakpoint-down(xs) {
      line-height: 20px;
    }
  }
}
