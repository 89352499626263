.files-dropzone {

    min-width: 600px;

    @include media-breakpoint-down(md) {
        min-width: 300px;
    }

    &__wrapper {

        min-height: 206px;
        border-radius: 3px;
        border: 2px dashed #9bacb6;
        background-color: #f7f9fa;
        padding: 15px;
        //margin: 10px 0;
        margin: 0;
        
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;


        &.is-highlighted {
            background: #e7f2f9;
        }
    }

    &__file {
        display: none;
    }
    
    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #e7f2f9;
        
        width: 150px;
        height: 120px;
        

        padding: 10px;
        border-radius: 10px;
        margin: 5px;
        border: 2px solid $main-color;
        transition: .3s;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 3px 10px #b7b7b7;
        }

        .updfile {
            &__name {
                background: #f7f9fa;
                padding: 0 10px;
                border-radius: 10px;
                max-width: calc(100% + 10px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &__size {

            }
            &__delete {
                button {
                    background: none;
                    border: none;
                }
            }
        }
        
    }
}