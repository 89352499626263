.ordercard {
    display: grid;
    grid-gap: 25px 20px;
    grid-template-columns: repeat(auto-fill, 290px);
    margin: 20px 0 0;

    @include media-breakpoint-down(md) {
        justify-content: center;
    }

    &.wide-layout {
        margin-left: -65px;
        margin-right: -65px;
        justify-content: center;

        @media screen and (max-width: 1270px) {
            margin: 20px 0 0;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-self: center;
        width: 100%;
        max-width: 290px;
        perspective: 2000px;

        @include media-breakpoint-down(lg) {
            max-width: 297px;
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        transition: transform 0.4s cubic-bezier(0.23, 0.74, 0.44, 1.23);
        transform-style: preserve-3d;
        box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
        border-radius: 8px;
        padding: 0 15px 18px;

        &.is-flipped {
            transform: rotateY(180deg);
        }

        &-front {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            backface-visibility: hidden;
        }

        &-back {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            transform: rotateY(180deg);
            width: 100%;
            height: 100%;
            padding: 0 15px;
            backface-visibility: hidden;
            background-color: #fff;
            border-radius: 8px;

            .files-footer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 43px;
                margin: auto -15px 20px;
                border-radius: 0 0 8px 8px;

                .file-back {
                    border: none;
                    background-color: transparent;
                    box-shadow: none;
                    font-weight: 600;
                    border-left: 2px solid #eaf0f4;
                    border-right: 2px solid #eaf0f4;
                    height: 100%;
                    min-width: 100px;
                    transition: $transition;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }

    &__files-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: 240px;
        margin-top: 10px;
        padding-right: 10px;
        @include customize-scrollbars(4px, $main-color, #fbfbfb, visible);
    }

    &__file {
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 2px solid #eaf0f4;
            padding-bottom: 10px;
            margin-bottom: 15px;
        }

        a {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            color: #3b454b;
            transition: $transition;
            cursor: pointer;

            &:hover {
                span {
                    color: #7f92f3;
                }
            }

            svg {
                min-width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            span {
                text-overflow: ellipsis;
                max-width: 220px;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        span {
            color: #9bacb6;
            font-size: 14px;
        }

        .file-icon {
            display: inline-block;

            svg {
                min-width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        min-height: 43px;
        background-color: #eaf0f4;
        color: #ffffff;
        margin: 0 -15px;
        border-radius: 8px 8px 0 0;
        padding: 0 15px;
        font-weight: 600;
        font-size: 14px;

        &:hover {
            color: #ffffff;
        }

        .eye-icon {
            width: 15px;
            fill: currentColor;
            margin-left: auto;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__messages,
    &__revision {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
        background-color: #eaf0f4;
        margin: 0 -15px;
        color: #3b454b;
        font-weight: 700;
        transition: $transition;
        cursor: pointer;

        &:hover {
            color: #7f93f3;
        }

        svg {
            width: 20px;
            height: 20px;
            margin-right: 7px;
            fill: #d76c6d;
        }
    }

    &__messages {
        svg {
            margin-bottom: 5px;
        }
    }

    &__feedback {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #eaf0f4;
        height: 55px;
        margin: 0 -15px;

        .feedback-title {
            font-size: 15px;
        }

        .feedback-rating {
            display: flex;
            justify-content: center;
            backface-visibility: hidden;

            svg {
                &.empty {
                    fill: #dcdcdc;
                }

                &.full {
                    fill: #f3c539;
                }

                width: 17px;
                height: 17px;
                margin: 1.5px;
            }
        }
    }

    &__deadline {
        display: flex;
        align-items: center;
        color: #3b454b;
        margin-top: 8px;
        white-space: nowrap;
        letter-spacing: -0.5px;
        font-size: 15px;
        cursor: pointer;

        svg {
            fill: #d76c6d;
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }

    &__title {
        color: #3b454b;
        font-size: 16px;
        margin-top: 4px;
        line-height: 18px;
        font-weight: 700;
        cursor: pointer;
    }

    &__paperType,
    &__workType,
    &__pages {
        font-size: 14px;
        color: #9bacb6;
        line-height: 14px;
        cursor: pointer;
    }

    &__pages {
        display: inline-block;
    }

    &__files {
        display: inline-block;
        color: #7f93f4;
        font-weight: 600;
        font-size: 14px;
        margin-left: 3px;
        transition: $transition;

        &:hover {
            color: lighten(#7f93f4, 6);
        }
    }

    &__features {
        font-size: 14px;
        cursor: pointer;
    }

    &__bottom {
        margin-top: auto;
    }

    &__divider {
        height: 2px;
        background-color: #eaf0f4;
        width: 100%;
        border: none;
        margin: 12px 0;
    }

    &__topWriters,
    &__extraqualityCheck,
    &__qalityFeature,
    &__onePageSummary,
    &__preferredWriter {
        display: inline-block;
        padding-top: 2px;
    }

    &__topWriters {
        margin-right: 5px;
        color: #e4b526;
    }

    &__extraqualityCheck {
        margin-right: 5px;
        color: #ff507a;
    }

    &__qalityFeature {
        margin-right: 5px;
        color: #78cd62;
    }

    &__preferredWriter {
        margin-right: 5px;
        color: #7ac8ed;
    }

    &__onePageSummary {
        margin-right: 5px;
        color: #fc8300;
    }

    &__price {
        color: #9bacb6;
        font-size: 18px;
        font-weight: 700;
        text-align: center;

        sup {
            position: static;
            vertical-align: super;
        }
    }

    &__discount-info {
        display: inline-block;
        width: 0;
        color: #78cd62;
        font-size: 12px;
        font-weight: 600;
    }

    &__progress {
        margin: 10px 0 20px;
        text-align: center;

        .progress-text {
            display: inline-block;
            line-height: 18px;
            font-size: 15px;
            padding: 0 1rem;
        }

        .progress-container,
        .progress-bar {
            height: 6px;
            border-radius: 3px;
            background-color: #eaf0f4;
            margin-top: 7px;
        }

        .progress-bar {
            background-color: #78cd62;
            margin: 0;
            width: 50%;
        }
    }

    &__pending {
        text-align: center;
        margin-top: 10px;

        .pending-text {
            display: inline-block;
            line-height: 18px;
            font-size: 15px;
            padding: 0 1rem;
        }

        .custom-progress {
            &__text {
                display: none;
            }

            &__bar {
                height: 6px;
                border-radius: 3px;
                background-color: #eaf0f4;
                margin-top: 7px;
            }

            &__value {
                background-color: #78cd62;
                margin: 0;
                height: 6px;
                border-radius: 3px;
            }
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        border-radius: 8px;
        width: 100%;
        min-height: 45px;
        transition: $transition;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        margin: 15px 0 0;

        &.details {
            margin-bottom: 15px;
            max-width: 260px;

            svg {
                fill: #ffffff;
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }

        &.disabled {
            pointer-events: none;
            filter: grayscale(0.9) sepia(0);
        }

        .cart-icon {
            fill: #ffffff;
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        &.modify-btn {
            background-color: #59d053;

            &:hover {
                //box-shadow: 0 1px 5px 0 #59d053;
            }
        }
    }

    &__action-wrapper {
        display: flex;
        margin-top: 20px;
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        border: none;
        background-color: transparent;
        box-shadow: none;
        color: #3b454b;
        font-weight: 600;
        line-height: 20px;
        transition: $transition;

        &.edit {
            &:hover {
                color: $main-color;
            }

            &.disabled {
                pointer-events: none;
                color: #9bacb6;
            }
        }

        &.similar {
            max-width: 104px;
            border-left: 2px solid #eaf0f4;
            border-right: 2px solid #eaf0f4;
            padding: 0px 10px;
            text-align: center;

            &:hover {
                color: $main-color;
            }
        }

        &.hide {
            display: flex !important;
            padding: 0;

            &:hover {
                color: #d76c6d;
            }

            &.disabled {
                pointer-events: none;
                color: #9bacb6;
            }
        }
    }

    &__restore-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 43px;
        margin-top: 10px;
    }

    &__restore-button {
        border: none;
        background-color: transparent;
        box-shadow: none;
        font-weight: 600;
        border-left: 2px solid #eaf0f4;
        border-right: 2px solid #eaf0f4;
        height: 100%;
        min-width: 100px;
        transition: $transition;

        &:hover {
            color: $main-color;
        }
    }

    .placeholder-card {
        display: flex;
        flex-direction: column;
        height: 350px;
        //box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
        border-radius: 8px;
        padding: 0 15px 18px;

        &.transactions {
            height: 190px;
        }

        &__header {
            height: 40px;
            margin-left: -15px;
            margin-right: -15px;
            background: #eaf0f4;
            border-radius: 8px 8px 0 0;
            margin-bottom: 15px;
        }

        &__text {
            width: 150px;
            height: 10px;
            border-radius: 3px;
            background: #eaf0f4;
            margin-bottom: 10px;

            &.long {
                width: 200px;
            }
        }

        &__price {
            width: 120px;
            height: 30px;
            border-radius: 3px;
            background: #eaf0f4;
            align-self: center;
            margin-top: 15px;
        }

        &__button {
            border-radius: 8px;
            height: 43px;
            background: #eaf0f4;
            margin-top: auto;
            margin-bottom: 10px;
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
        }

        &__footer-block {
            border-radius: 3px;
            background: #eaf0f4;
            height: 40px;
            width: 75px;
        }
    }
}

.ordercard__item {
    &.unpaid {
        .ordercard {
            &__header {
                background-color: $warning;
            }

            &__price {
                font-size: 30px;
                line-height: 36px;
                color: #9bacb6;
                font-weight: 600;
                text-align: center;

                .colored-price,
                .colored-percent {
                    color: #78cd62;
                }
            }

            &__discount-info {
                display: block;
                width: initial;
                font-size: 14px;
                line-height: 14px;
                color: #9bacb6;

                .full-price {
                    text-decoration: line-through;
                }
            }

            &__btn {
                background-color: #59d053;

                &:hover {
                    //box-shadow: 0 1px 5px 0 #59d053;
                }
            }
        }
    }

    &.paid,
    &.in-progress,
    &.modified,
    &.offered {
        .ordercard {
            &__header {
                background-color: #7ac8ed;
            }

            &__deadline {
                font-size: 14px;
            }
        }
    }

    &.completed {
        .ordercard {
            &__header {
                background-color: #78cd62;
            }

            &__btn {
                background-color: #7f92f3;

                &:hover {
                    //box-shadow: 0 1px 5px 0 #7f92f3;
                }
            }
        }
    }

    &.canceled,
    &.refunded {
        .ordercard {
            &__header {
                background-color: #9bacb6;
            }
        }
    }

    &.hold {
        .ordercard {
            &__header {
                background-color: #d76c6d;
            }

            &__btn {
                background-color: #d76c6d;

                &:hover {
                    //box-shadow: 0 1px 5px 0 #d76c6d;
                    color: #fff;
                }
            }
        }
    }

    &.is-hidden {
        .ordercard {
            &__header {
                background-color: #c5ccd3;
                cursor: default;
            }

            &__date-created {
                font-size: 14px;
                margin-top: 8px;
            }

            &__details-block {
            }

            &__files {
                font-size: 13px;
                cursor: default;
            }

            &__title,
            &__files,
            &__topWriters,
            &__extraqualityCheck,
            &__qalityFeature,
            &__preferredWriter,
            &__date-created {
                color: #9bacb6;
            }
        }
    }
}

.orderlist__empty-msg {
    font-size: 25px;
    text-align: center;
    padding-top: 20px;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
        line-height: 28px;
    }
}
