.revisions {
    .info {
        display: flex;
        border-radius: 3px;
        background-color: #eaf0f4;
        padding: 20px 24px 15px;

        @include media-breakpoint-down(xs) {
            padding: 15px;
        }

        &__left {
            padding-top: 5px;

            svg {
                width: 40px;
                height: 38px;

                @include media-breakpoint-down(xs) {
                    width: 30px;
                    height: 28px;
                }
            }
        }

        &__right {
            padding-left: 20px;
        }

        &__list {
            padding-left: 15px;
        }

        &__list-item {
            list-style-type: disc;
        }

        &__text {
            margin-bottom: 0;
        }

        &__submit {
            background-color: transparent;
            box-shadow: none;
            outline: none;
            padding: 0;
            border: none;
        }

        a,
        &__submit {
            color: #7f92f3;
            font-weight: 600;
            transition: $transition;

            &:hover {
                color: lighten(#7f92f3, 5%);
            }
        }
    }

    .list {
        display: grid;
        grid-gap: 25px 20px;
        grid-template-columns: repeat(auto-fill, 290px);
        margin: 20px 0 0;
        align-items: flex-start;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        &.wide-layout {
            margin-left: -65px;
            margin-right: -65px;
            justify-content: center;

            @media screen and (max-width: 1270px) {
                margin: 20px 0;
            }
        }

        &__item {
            box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
            background-color: #f7f9fa;
            border-radius: 8px;
            overflow: hidden;
            padding: 0 17px;
        }
    }

    .card {
        &__title {
            display: flex;
            align-items: center;
            min-height: 43px;
            font-size: 16px;
            padding: 5px 17px;
            margin: 0 -17px;
            color: #ffffff;
            cursor: pointer;
        }

        &__body {
            padding: 10px 0;
        }

        &__deadline {
            font-size: 14px;
        }

        &__description {
            margin: 0;
            font-weight: 600;
        }

        &__footer {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #9bacb6;
            border-top: 2px solid #eaf0f4;
            min-height: 42px;
        }

        &__created {
            margin-bottom: 2px;
        }

        &.requested,
        &.in-progress,
        &.disputed,
        &.rejected,
        &.graded,
        &.verifying {
            .card__title {
                background-color: #7ac8ed;
            }
        }

        &.completed,
        &.closed {
            .card__title {
                background-color: #78cd62;
            }
        }

        &.canceled,
        &.failed,
        &.expired,
        &.undelivered {
            .card__title {
                background-color: #d76c6d;
            }
        }
    }
}

@import "view-modal";
@import "create-modal";
