.profile {
    &__wrapper {
        //max-width: 800px;
    }

    .heading {
        margin: 0;
        @include media-breakpoint-down(xs) {
            margin-bottom: 20px;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        @include media-breakpoint-down(xs) {
            flex-direction: column;
            align-items: stretch;
        }
    }
    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
        }
    }

    &__email,
    &__phone,
    &__name {
        &-group {
            margin-bottom: 10px;
        }

        &-label {
            display: inline-block;
            color: #9bacb6;
            font-size: 14px;
            font-weight: 600;
            // border-bottom: 2px dashed #9bacb6;
            margin-bottom: 10px;

            button {
                color: $main-color;
                border: none;
                box-shadow: none;
                background-color: transparent;
                padding: 0;
                font-weight: 600;

                &.confirmed {
                    color: #59d053;
                    cursor: default;
                }

                &.await {
                    color: #7f92f3;
                    cursor: default;
                }
            }
        }

        &-value {
            display: flex;
            align-items: center;
            height: 46px;
            border-radius: 8px;
            border: 2px solid #f7f9fa;
            background-color: #f7f9fa;
            font-size: 16px;
            font-weight: 600;
            color: $color-text;
            padding-left: 22px;
            transition: $transition;

            @include media-breakpoint-down(xs) {
                font-size: 15px;
            }

            &::placeholder {
                color: #9bacb6;
            }
        }
    }

    &__phone,
    &__name {
        &-group {}

        &-label {}

        &-value {
            background-color: transparent;

            &:hover,
            &:active {
                border-color: $main-color;
            }

            &:focus {
                //box-shadow: 0 10px 35px $main-color;
                border: 2px solid $main-color !important;
            }

            &.done {
                //color: $main-color;
                border-color: $main-color;
            }
        }
    }

    &__name {
        &-group {
            margin-bottom: 20px;
        }
    }

    &__checkbox-group {
        margin-bottom: 20px;
    }

    &__privacy-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text;
        width: 258px;
        height: 45px;
        margin-bottom: 35px;
        border-radius: 8px;
        border: 2px solid #e1e6e8;
        font-size: 18px;
        font-weight: 600;
        transition: $transition;
        box-shadow: none;
        background-color: transparent;

        @media screen and (max-width: 350px) {
            width: 100%;
        }

        &:hover {
            background-color: $main-color;
            border-color: $main-color;
            opacity: 0.7;
            color: #fff;
        }
    }

    &__footer {
        //border-top: 2px solid #eaf0f4;
        //padding-top: 25px;
    }

    &__update-btn {
        border: none;
        box-shadow: none;
        border-radius: 8px;
        background-color: #f7f9fa;
        height: 45px;
        width: 258px;
        color: #aeaeae;
        font-size: 18px;
        font-weight: 600;
        transition: $transition;
        pointer-events: none;

        @media screen and (max-width: 350px) {
            width: 100%;
        }

        &.active {
            color: #fff;
            background-color: $main-color;
            pointer-events: all;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .checkbox {
        &__label {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding-left: 0;
            height: initial;
            color: #3b454b;


            &:before {
                display: none;
            }

            span {
                font-size: 16px;
                font-weight: 500;

                @include media-breakpoint-down(sm) {
                    //line-height: 25px;
                    //font-size: 19px;
                    //font-weight: 500;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 15px;
                }
            }

            &:hover {
                .checkbox__button {
                    border-color: $main-color;
                }
            }
        }

        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            order: -1;
            width: 20px;
            height: 20px;
            border: 2px solid #e1e6e8;
            background-color: #ffffff;
            border-radius: 4px;
            margin-right: 15px;
            transition: $transition;

            @include media-breakpoint-down(xs) {
                //min-width: 40px;
                //height: 40px;
                flex-shrink: 0;
            }

            &-arrow {
                width: 10px;
            }

            svg {
                fill: #fff;
                transition: $transition;
            }
        }

        &__link {
            color: #7f92f3;
            font-weight: 600;
            transition: $transition;

            &:hover {
                color: lighten(#7f92f3, 5%);
            }
        }

        &__dashed {
            // border-bottom: 2px dashed #9bacb6;
        }
    }

    .checkbox__input:checked+.checkbox__label {
        .checkbox__button {
            border-color: $main-color;

            svg {
                fill: $main-color;
            }
        }
    }

    .checkbox__input:focus+.checkbox__label {
        .checkbox__button {
            box-shadow: 0 10px 35px $main-color !important;
        }
    }

    .password {
        //background-color: #f7f9fa;
        border-radius: 8px;
        border: 2px solid $grey-color;
        margin-bottom: 35px;

        &__field {
            margin-bottom: 16px;

            input {
                border: 2px solid $grey-color;
                background-color: #fff;
                &:hover, &:focus {
                    border-color: $main-color;
                }
            }
        }

        &__label {
            color: #9bacb6;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 15px;

            @media screen and (max-width: 380px) {
                margin-bottom: 5px;
            }
        }

        &__value {
            border: none;
            height: 46px;
            border-radius: 8px;
            padding: 20px 25px;
            font-size: 16px;

            @include media-breakpoint-down(xs) {
                font-size: 15px;
            }

            &::placeholder {
                color: $color-text;
                font-size: 16px;
                font-weight: 600;

                @include media-breakpoint-down(xs) {
                    font-size: 15px;
                }
            }
        }

        &__save-btn {
            margin-left: auto;
            margin-bottom: 20px;
            border: none;
            box-shadow: none;
            border-radius: 8px;
            background-color: #f7f9fa;
            height: 45px;
            width: 258px;
            color: #aeaeae;
            font-size: 18px;
            font-weight: 600;
            transition: $transition;
            pointer-events: none;

            @media screen and (max-width: 350px) {
                width: 100%;
            }

            &.active {
                color: #fff;
                background-color: $main-color;
                pointer-events: all;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .panel {
            &__forgot-btn {
                position: absolute;
                top: 0;
                right: 25px;
                border: none;
                box-shadow: none;
                background-color: transparent;
                padding: 0;
                font-size: 14px;
                color: #9bacb6;
                transition: $transition;
                font-weight: 600;

                @media screen and (max-width: 380px) {
                    position: static;
                    margin-bottom: 15px;
                }

                &:hover {
                    color: lighten(#9bacb6, 8);
                }
            }

            &__head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #3b454b;
                font-size: 18px;
                font-weight: 600;
                padding: 15px 25px;
                //height: 55px;
                //cursor: pointer;
                transition: $transition;
                border-bottom: 2px solid $grey-color;

                @include media-breakpoint-down(xs) {
                    font-size: 19px;
                    font-weight: 500;
                    padding: 0 20px;
                }

                &:hover {
                    //color: $main-color;

                    svg {
                        //fill: $main-color;
                    }
                }

                &.opened {
                    color: $main-color;

                    svg {
                        fill: $main-color;
                        transform: rotate(180deg);
                    }
                }

                svg {
                    width: 20px;
                    height: 13px;
                    transition: $transition;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                position: relative;
                //height: 405px;
                padding: 0 25px;
                transition: $transition;
                overflow: hidden;

                @media screen and (max-width: 380px) {
                    //height: 415px;
                    padding: 0 20px;
                }

                &.collapsed {
                    height: 0;
                }
            }
        }
    }
}

@import './privacy';