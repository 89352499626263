.discount {
    &__code-wrapper {
        display: flex;
        background-color: #eaf0f4;
        padding: 12px 20px 1px 23px;
        margin-bottom: 70px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 40px;
        }

        p {
            margin-bottom: 10px;
        }
    }

    &__code-left {
        width: 40px;
        height: 40px;
        margin-right: 15px;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &__code-title {
        color: #3b454b;
        font-weight: 700;
        font-size: 18px;

        @include media-breakpoint-down(xs) {
            font-size: 17px;
            line-height: 20px;
        }
    }

    &__create {
        border: none;
        box-shadow: none;
        padding: 0;
        offset: none;
        background-color: transparent;
        font-size: 20px;
        color: #7f92f3;
        font-weight: 600;

        @include media-breakpoint-down(xs) {
            font-size: 17px;
            margin-bottom: 10px;
        }
    }

    &__generate-box {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(xs) {
            justify-content: center;
            margin-bottom: 0;
        }
    }

    &__link {
        color: $main-color;
        font-weight: 600;
        transition: $transition;
    }
}

.code-box {
    &__regenerate {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
        offset: none;
    }

    &__regenerate,
    &__copy,
    &__order {
        @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
        }
    }

    &__regenerate {
        width: 32px;
        height: 36px;

        svg {
            width: 16px;
            height: 16px;
            fill: #9BACB6;
        }
    }

    &__code {
        font-size: 18px;
        color: #7f92f3;
        margin: 0 13px 0 7px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
        }
    }

    &__copy {
        margin-right: 15px;
    }

    &__divider {
        width: 2px;
        height: 35px;
        background-color: #c6d5df;
        margin-right: 15px;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &__order {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background-color: #59d053;
        border-radius: 20px;
        height: 35px;
        padding: 0.3rem 0.8rem;
        color: #fff;
        transition: $transition;

        @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
        }

        &:hover {
            color: #fff;
            //box-shadow: 0 1px 5px 0 #59d053;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: #fff;
            margin-right: 10px;
        }
    }
}