body {
  background: #fff;
}

// html,
// body,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     font-family: "Nunito", sans-serif;
// }

.page {
  min-height: 100vh;

  > .container:first-child {
  }

  &.error {
    h1 {
      color: $main-color;
    }
  }

  &:not(.fullscreen) {
  }

  &.is-authorized {
  }

  &.is-guest {
  }
}

.menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;

  &__list {
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    position: relative;
    svg {
      width: 18px;
    }
  }

  &__link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &.active {
    }

    .anticon {
      margin-right: 8px;
      margin-bottom: 1px;
    }
  }
}

.site-header {
  padding: 15px;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  margin-bottom: -75px;
  transition: all 0.15s ease-in-out;

  &.fixed {
    padding: 5px 0;
    box-shadow: 0 5px 15px rgba(black, 0.4);

    .logo {
      height: 40px;
      margin: 2px 0;
    }

    .back-to-site {
      strong {
        max-width: 0;
      }
    }
  }

  .logo {
    height: 45px;
    transition: all 0.15s ease;
  }

  .menu {
    &__toggle {
      display: none;
      z-index: 100;
      position: fixed;
      right: 1rem;
      background: transparent;
      border: none;
      transition: all 0.2s ease-in-out;

      gap: 10px;
      flex-flow: column;

      span {
        height: 2px;
        width: 45px;
        display: grid;
        box-shadow: 1px 1px 1px rgba(black, 0.5);
        background: white;
        border-radius: 15px;
        transform-origin: 70%;
        transition: all 0.1s ease;

        &:nth-child(1) {
        }

        &:nth-child(2) {
          width: 25px;
        }

        &:nth-child(3) {
        }
      }

      &.is-opened {
        // transform: scale(0.8);
        span {
          &:nth-child(1) {
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            transform: rotate(45deg);
          }
        }
      }

      // @include media-breakpoint-down(xl) {
      //     display: grid;
      // }
    }
    &__list {
      // @include media-breakpoint-down(xl) {
      //     display: flex;
      //     flex-direction: column;
      //     background: #0d0a15;
      //     position: absolute;
      //     right: 0;
      //     bottom: 0;
      //     top: 0;
      //     width: 300px;
      //     height: 100vh;
      //     justify-content: center;
      //     transform: translateX(100%);
      //     box-shadow: -5px 0 15px rgba(#17004a, 0.2);
      //     border-left: 1px solid #282333;
      // }
      &.is-opened {
        transition: transform 0.2s ease-in-out;
        transform: translateX(0);
        a {
          font-size: 1.5rem;
        }
        li {
          padding: 0.2rem 0;
        }
      }
    }
    &__item {
      display: inline-flex;
      align-items: center;

      //&:not(:last-child)::after {
      //  content: '/';
      //  margin-left: 0.8rem;
      //  opacity: 0.2;
      //
      //  @include media-breakpoint-down(sm) {
      //    display: none;
      //  }
      //}
      a {
        position: relative;
        z-index: 1;
        transition: all 0.15s ease;

        &::before {
          position: absolute;
          content: '';
          left: 50%;
          right: 50%;
          opacity: 0;
          bottom: 0;
          height: 2px;
          border-radius: 4px;
          background: $primary;
          z-index: -1;
          transition: all 0.2s ease;
        }
      }

      &.is-current {
        a {
          &::before {
            opacity: 1;
            left: 0;
            right: 0;
          }
        }
      }
      // Unique items
      span {
        font-size: 24px;
      }

      em {
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        //margin-left: 0.8ch;
        max-width: 180px;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }

  &.sticky {
    background: rgba(black, 0.8);
    padding: 8px 0;
    box-shadow: 0 4px 10px rgba(black, 0.2), 0 1px 1px rgba(white, 0.04);

    backdrop-filter: blur(3px); // important

    .logo {
      width: 120px;
    }

    .menu {
      &__item {
        a {
          padding: 0 0.2rem;
          &::after {
            display: none;
          }
        }
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }
}

.site-footer {
  // @include media-breakpoint-down(sm) {
  //     &.section {
  //         padding-left: 0;
  //         padding-right: 0;
  //     }
  // }

  &__container {
    text-align: left;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 30px;
    justify-content: start;
    justify-items: start;

    // max-width: 900px;
    font-size: 15px;
    margin: 1rem auto;

    p {
      a {
        text-decoration: underline;
        &:hover {
        }
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
  }

  &__copyright {
    grid-column: 1 / -1;
  }

  .menu {
    margin-bottom: 2rem;
    font-size: 1rem;

    &__list {
      margin: auto;
      display: grid;
      grid-auto-flow: column;
      justify-content: center;
      gap: 2rem;

      // @include media-breakpoint-down(lg) {
      //     display: grid;
      //     grid-auto-flow: unset;
      //     grid-template-columns: repeat(4, 1fr);
      //     text-align: center;
      //     gap: 1rem;
      // }
      // @include media-breakpoint-down(sm) {
      //     grid-template-columns: repeat(3, 1fr);
      // }
    }

    &__item {
    }

    &__link {
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      grid-template-columns: 1fr;
    }
    &__copyright {
      grid-column: initial;
    }
  }
}
