.tablinks {
    &-header {
        &__list {
            margin-bottom: 0;
            padding: 0 30px;
            display: flex;
            width: 100%;

            //@media screen and (max-width: 350px) {
            //    padding: 0;
            //}
            @include media-breakpoint-down(xs) {
                padding: 0 20px;
            }

            li {
                padding: 0 15px;
                @include media-breakpoint-down(md) {
                    flex-grow: 1;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0 10px;
                }
                @include media-breakpoint-down(xs) {
                    //padding: 0 8px;
                }
            }
        }
        &__link {
            display: flex;
            align-items: center;
            svg {
                width: 16px;
                margin-right: 8px;
                margin-bottom: 1px;
                fill: $color-text;
                transition: $transition;
                @include media-breakpoint-down(sm) {
                    margin: 0;
                }
            }
            &:hover {
                color: $main-color !important;
                svg {
                    fill: $main-color;
                }
            }
            &.active {
                //background-color: red;
                color: $main-color !important;
                svg {
                    fill: $main-color;
                }
            }
        }
        &__label {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        &__counter {
            display: inline-block;
            margin-left: 5px;
            color: #9bacb6;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            .counter-value {
                color: $main-color;
            }
        }
    }
    &__list {
        display: flex;
        margin-bottom: 16px;
        //border-bottom: 2px solid #eaf0f4;
        //width: 100%;
        width: fit-content;
        padding: 0 10px;

        border: 2px solid $grey-color;
        border-radius: 10px;

        @media screen and (max-width: 350px) {
            padding: 0;
        }

        li {
            display: flex;
            align-items: center;
            margin-right: 10px;
            &:not(:last-child) {
                border-right: 2px solid $grey-color;
            }
            @include media-breakpoint-down(md) {
                flex-grow: 1;
                margin-right: 0;
                padding: 0 5px;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        color: #3b454b;
        //font-weight: 700;
        padding: 3px 10px;
        //border: 2px solid transparent;
        border-radius: 5px 5px 0 0;
        min-height: 34px;
        height: calc(100% + 2px);
        transition: $transition;




        @include media-breakpoint-down(md) {
            padding: 3px 8px;
            justify-content: center;
        }

        @include media-breakpoint-down(xs) {
            padding: 3px 5px;
        }

        &.active {
            //border-color: #eaf0f4;
            //border-bottom-color: #fff;

            svg {
                fill: $main-color;
            }

            .tablinks__label {
                display: inline;
            }

            .tablinks__counter {
                margin-right: 0;
            }
        }

        &:hover {
            color: $main-color;

            svg {
                fill: $main-color;
            }
        }

        .tablinks__counter {
            display: inline-block;
            margin-top: -3px;
            margin-left: 5px;
            color: #9bacb6;
            font-weight: 600;

            @include media-breakpoint-down(md) {
                font-size: 13px;
                margin-top: -5px;
            }

            @include media-breakpoint-down(sm) {
                margin-top: -13px;
                margin-left: 2px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 11px;
            }

            .counter-value {
                color: $main-color;
            }
        }

        svg {
            width: 16px;
            fill: #9BACB6;
            transition: $transition;

            @include media-breakpoint-down(sm) {
                width: 18px;
            }

            @media screen and (max-width: 350px) {
                width: 16px;
            }

            &.new-order {
                fill: #5ed257;
            }
        }
    }

    &__label {
        margin-left: 8px;
        font-size: 16px;

        @include media-breakpoint-down(md) {
            font-size: 14px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}