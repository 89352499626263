.select {
    &__row {
        display: flex;
        justify-content: space-between;

        .select__group:first-child {
            min-width: 55%;
            margin-right: 16px;
        }

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;

            .select__group:first-child {
                margin-right: 0;
            }
        }
    }

    &__group {
        flex-basis: 100%;
        margin-bottom: 20px;
    }

    &__box {
        position: relative;
    }

    &__placeholder {
        display: flex;
        align-items: center;
        height: 46px;
        border-radius: 8px;
        border: 2px solid $grey-color;
        background-color: $grey-color;
        padding: 0 20px;
        font-size: 16px;
        color: $color-text;
        font-weight: 500;
        cursor: pointer;
        transition: $transition;

        &.is-active {
            border: 2px solid $main-color;
            background-color: #fff;
            //color: $main-color;

            .select__arrow {
                fill: #3b454b;
            }
        }

        &:hover {
            //box-shadow: 0 10px 35px $main-color;
            border: 2px solid $main-color;
            //background-color: $main-color;
            color: $main-color;

            .orderform__check-arrow {
                fill: $main-color;
            }

            .select__arrow {
                fill: $main-color;
            }
        }

        &:focus {
            //box-shadow: 0 10px 35px $main-color;
            border: 2px solid $main-color;
        }

        &.error {
            border: 2px solid $error-color;
            background-color: $grey-color;
            color: #3b454b;
            box-shadow: none;

            .select__arrow {
                fill: #3b454b;
            }
        }

        &.is-disabled {
            border: 2px solid $grey-color;
            background-color: $grey-color;
            color: #ccd5db;
            box-shadow: none;
            pointer-events: none;

            .orderform__check-arrow {
                fill: $grey-color;
            }

            .select__arrow {
                fill: $grey-color;
            }
        }
    }

    &__arrow {
        width: 12px;
        fill: #3b454b;
        margin-left: auto;
        transition: $transition;
    }

    &__dropdown {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 8px;
        background-color: $grey-color;
        box-shadow: 0 10px 35px rgba(29, 42, 50, 0.2);
        overflow: hidden;
        z-index: 10;
    }

    &__search {
        //font-size: 22px;
        font-weight: 500;
        padding: 5px 15px 5px 25px;
        height: initial;
        background-color: #fff;
        border: none;
        -webkit-appearance: none;
        box-shadow: none;
    }

    &__list-wrapper {
        background-color: $grey-color;
        max-height: 257px;
        overflow-y: auto;
        @include customize-scrollbars(10px, #cdcdcd, #f0f0f0, visible);

        &::-webkit-scrollbar-track {
            border-radius: 0;
            box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
        }
    }

    &__list {
        margin-bottom: 0;
        overflow: hidden;

        &-group {
            > span {
                display: block;
                text-align: center;
                font-weight: 700;
                padding: 5px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                border-top: 1px solid rgba(0, 0, 0, 0.07);
                background: #f2f2f2;
            }
        }
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        //font-size: 22px;
        line-height: 33px;
        font-weight: 500;
        cursor: pointer;
        padding: 5px 15px 5px 25px;
        transition: $transition;

        .StarIcon {
            display: inline-block;
            fill: orange;
            animation: popUp 0.15s ease both;
        }

        .select__option-check {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            fill: $grey-color;
            transition: $transition;
        }

        &.selected {
            background-color: #eeeeee;

            .select__option-check {
                fill: #eeeeee;
                transform: scale(0.9);
                display: inline-block;
            }
        }

        &:hover {
            background-color: $main-color;
            color: #ffffff;

            .select__option-check {
                fill: $main-color;
            }

            .fa-star {
                color: #ffffff;
            }
        }

        &[data-selected="selected"] {
            background-color: $main-color;
            color: #ffffff;

            .select__option-check {
                fill: #fff;
            }

            .StarIcon,
            .fa-star {
                color: #ffffff;
                fill: #fff;
                animation: pulse 0.2s ease both;
            }
        }

        .fa-star {
            color: $main-color;
        }
    }
}
