$main-color: #84c0cd !default;
$primary: #84c0cd !default;
$primary-text: white !default;

html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#root {
  .site-header {
    padding: 12px 0;
    min-height: 70px;
    background: white;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    box-shadow: 0 2px 15px rgba(black, 0.15);

    &,
    a {
      color: #5c6871;
      font-size: 16px;
      svg {
        color: $main-color;
      }
    }

    &__container {
      > a {
        display: flex;
        align-items: center;
      }
    }

    img.logo {
      max-height: 28px;
    }
  }
  .ant-btn-primary {
    background: $primary;
    color: $primary-text;
  }

  .link {
    color: $primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &.active {
    }
  }
}

#page.page-wrapper {
    overflow: visible;
}

.is-messenger-opened {
    @media screen and (max-width: 767px) {
        overflow: hidden;
        position: relative;
    }
}

@import "variables";
@import "global", "animations", "intl-tel-input", "infinite-calendar";
// Components
@import "App";
@import "orderform";
@import "./dashboard/index";
@import "Notification";
@import "login";

@import "layout";
