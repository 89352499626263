.files {
    @import "files-dropzone.scss";
    @import "files-view.scss";
    @import "files-support.scss";
    &-head {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;
        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
        }
    }
}