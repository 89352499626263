.details__table {
    //display: flex;
    //flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 20px;
    margin-bottom: 15px;
    gap: 4px;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
    .table {
        &__item {
            background-color: $grey-color;
            border-radius: 8px;
            padding: 10px 20px;
            //padding-right: 40px;
            //width: 29%;
            //margin-bottom: 8px;


            @include media-breakpoint-down(lg) {
                //width: 33%;
            }

            @include media-breakpoint-down(md) {
                //width: 50%;
            }

            @include media-breakpoint-down(xs) {
                //width: 100%;

                &:not(:last-child) {
                    //border-bottom: 2px solid #eaf0f4;
                }
            }

            &.full {
                //width: 100%;
                grid-column: span 3;
                @include media-breakpoint-down(md) {
                    grid-column: span 2;
                }
                @include media-breakpoint-down(sm) {
                    grid-column: span 1;
                }

                .table__text {
                    font-weight: normal;
                    font-style: italic;
                    font-size: 17px;
                }
            }
        }

        &__review {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .feedback-rating {
                svg {
                    &.empty {
                        fill: #dcdcdc;
                    }

                    &.full {
                        fill: #f3c539;
                    }
                }
            }
        }

        &__label {
            font-size: 14px;
            color: #9bacb6;
            font-weight: 500;
            margin-bottom: 4px;
        }

        &__text {
            font-size: 16px;
            color: #3b454b;
            font-weight: 700;
            line-height: 22px;
            margin-bottom: 8px;
        }
    }
}