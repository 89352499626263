.formField {
    &__group {
        margin-bottom: 20px;
    }

    &__input {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 2px solid $grey-color;
        height: 46px;
        min-height: 46px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 500;
        color: #3b454b;
        background-size: 21px 16px;
        background-position: 25px 16px;
        transition: $transition;
        max-height: 160px;

        @include media-breakpoint-down(xs) {
            padding: 0 15px;
        }

        &::placeholder {
            color: #9bacb6;
        }

        &:hover,
        &:active,
        &:focus{
            border: 2px solid $main-color !important;
            color: $main-color;
        }

        &.done {
            //color: $main-color;
            border: 2px solid $main-color;
            background-size: 21px 16px;
            background-position: 25px 16px;
            padding-left: 25px;
        }

        &.error {
            border: 2px solid #d76c6d;
            color: #d76c6d;

            &:focus {
                color: #3b454b;
            }
        }
    }

    textarea {
        padding-top: 13px;
        padding-bottom: 10px;
        font-size: 17px;
        overflow-y: auto;
        @include customize-scrollbars(10px, #cdcdcd, #f0f0f0, visible);

        &::-webkit-scrollbar-track {
            border-radius: 0;
            box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
        }

        @include media-breakpoint-down(lg) {
            font-size: 16px;
        }

        @include media-breakpoint-down(sm) {
            // font-size: 16px;
            padding-top: 5px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 13.5px;
            padding-top: 8px;
        }
    }

    &__tel-wrapper {
        display: flex;
        align-items: center;

        input[type=tel].tel-input {
            display: none;
        }

        .intl-tel-input {
            position: relative;
            // width: 122px;
            height: 46px;
            margin-right: 2px;
            min-width: 100px;

            .country-list {
                z-index: 10;
            }

            @include media-breakpoint-down(xs) {
                width: auto;
                // min-width: 100px;
            }

            .selected-flag {
                top: 0px !important;

                @include media-breakpoint-down(xs) {
                    min-width: 60px !important;
                    width: auto;
                    text-align: center;
                }
            }

            .iti-flag {
                transform: scale(1.5);
                left: 4px;

                @include media-breakpoint-down(xs) {
                    // top: 14px;
                    // left: 18px;
                    // bottom: unset;
                }
            }

            .selected-dial-code {
                font-size: 16px;
                font-weight: 500;
                padding-left: 35px;

                @include media-breakpoint-down(xs) {
                    //font-size: 19px;
                    // padding-left: 0;
                    // padding-top: 30px;
                    // padding-right: 5px;
                }
            }
        }

        .formField {
            flex-grow: 1;
        }
    }
}