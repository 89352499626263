@keyframes pulse {
    50% {
        transform: scale(0.9);
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.zoomIn {
    animation: zoomIn 0.4s ease-in-out both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 0.4s ease-in-out both;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    animation: fadeInUp 0.4s ease-in-out both;
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(100px);
    }
}

.fadeOut {
    animation: fadeOut 0.4s ease-in-out both;
}

@keyframes notifyShow {
    from {
        opacity: 0;
        transform: scale(0.95) translateY(-100px);
    }

    to {
        transform: scale(1);
    }
}

.notifyShow {
    animation: notifyShow 0.4s ease-in-out both;
}

@keyframes notifyHide {
    from {
        opacity: 1;
        transform: scale(1);
    }

    30% {
        opacity: 0.9;
        transform: scale(1.1) translateY(20px);
    }

    to {
        opacity: 0;
        transform: scale(0.9) translateY(-120px);
    }
}

.notifyHide {
    animation: notifyHide 0.6s ease-in-out both;
}

// Spinner
@keyframes atom-spinner-animation-1 {
    100% {
        transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
    }
}

@keyframes atom-spinner-animation-2 {
    100% {
        transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
    }
}

@keyframes atom-spinner-animation-3 {
    100% {
        transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
    }
}

// Custom popup 
@keyframes popUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1.0);
    }
}